import '../styles/MainSectionElSalvador.css';
import { FaLocationPin, FaEnvelope, FaPhone, FaStar } from "react-icons/fa6";
import AppScreenshot from '../assets/img/app-screenshot.png';
import DownloadAppStore from '../assets/img/appstore.svg';
import DownloadPlayStore from '../assets/img/google.svg';
import DownloadAppGallery from '../assets/img/appgallery.svg';
import CoinsIcon from '../assets/img/coins.png';
import TruckIcon from '../assets/img/truck.png';
import MapIcon from '../assets/img/map.png';
import BarllenoLogoCuadrado from '../assets/img/barlleno-logo-cuadrado.png';
import VisaLogo from '../assets/img/visa-logo.png';
import MastercardLogo from '../assets/img/mastercard-logo.png';
import InstagramLogo from '../assets/img/instagram-logo.png';
import FacebookLogo from '../assets/img/facebook-logo.png';
import TabletScreenshot from '../assets/img/barlleno-screenshot-tablet.png';
import BarllenoPhoneBlack from '../assets/img/barlleno-phone-black.png';
import BarllenoPhoneWhite from '../assets/img/barlleno-phone-white.png';
import ProductsCarousel from './ElSalvador/ProductsCarousel';
import ProductsCarouselLargeScreen from './ElSalvador/ProductsCarouselLargeScreen';
import ValorationsCarousel from './ElSalvador/ValorationsCarousel';
import { productsBarlleno } from '../data/ElSalvadorData';

const MainSectionElSalvador = () => {
    return (
        <div className="flex flex-col items-center w-full">
            <div className="w-full flex flex-col items-center lg:flex-row lg:justify-center">
                <div className='lg:w-[50%] flex flex-row justify-center relative'>
                    <img src={AppScreenshot} alt='Barlleno app' className='w-[250px] lg:hidden'></img>
                    <img src={TabletScreenshot} alt='Barlleno app' className='w-[500px] hidden lg:block'></img>
                    <div className='absolute bottom-10 right-0'>
                        <img src={AppScreenshot} alt='Barlleno app' className='w-[250px] hidden xl:block'></img>
                    </div>
                </div>

                <div className='lg:w-[50%]'>
                    <div className='text-center lg:text-start px-3'>
                        <p className='text-2xl font-bold text-barlleno-blue lg:text-4xl'>Barlleno APP, la única drink delivery APP de Latinoamérica</p>
                    </div>

                    <div className='text-center pt-3 lg:text-start px-3'>
                        <p className='text-2xl font-semibold text-barlleno-red'>Todos los productos que necesitas para tomar en una sola APP</p>
                    </div>

                    <div className='w-full flex justify-center pt-3 px-3 lg:justify-start'>
                        <a href='https://barlleno.app' target='_blank' className='w-full'><button className='bg-barlleno-red text-white p-4 rounded-full w-full font-bold uppercase text-md lg:w-[70%] hover:bg-barlleno-blue'>Visita nuestro web commerce</button></a>
                    </div>

                    <div className='w-full flex justify-between sm:justify-center py-3 px-3 lg:justify-start'>
                        <div>
                            <a href="https://apps.apple.com/us/app/barlleno/id1506149704?l=es&ls=1" target='_blank'>
                                <img src={DownloadAppStore} alt='Appstore' className='w-[124px]'></img>
                            </a>
                        </div>

                        <div className='sm:mx-4'>
                            <a href="https://play.google.com/store/apps/details?id=app.barlleno.premier ">
                                <img src={DownloadPlayStore} alt='Playstore' className='w-[140px]'></img>
                            </a>
                        </div>

                        <div>
                            <a href="https://appgallery.huawei.com/#/app/C102795087">
                                <img src={DownloadAppGallery} alt='Appgallery' className='w-[140px]'></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-col items-center'>
                <div className='text-center pt-3 lg:w-[60%] lg:pt-6'>
                    <p className='text-2xl font-bold text-barlleno-blue lg:text-4xl'>Descarga Barlleno APP y disfruta de beneficios exclusivos</p>
                </div>

                <div className='text-center pt-3 lg:w-[60%]'>
                    <p className='text-2xl font-semibold text-barlleno-red'>Abre las puertas a un mundo de ventajas que solo nuestros usuarios disfrutan</p>
                </div>

                <div className='w-full flex flex-col items-center p-4 text-barlleno-blue lg:flex-row lg:justify-evenly'>
                    <div className='card w-full flex flex-col items-center py-4 text-center rounded-2xl lg:w-[30%]'>
                        <div>
                            <img src={CoinsIcon} alt='Acumula puntos'></img>
                        </div>

                        <div className='mt-4 px-2'>
                            <p className='text-xl font-semibold'>Acumula puntos y canjéalos por productos exclusivos</p>
                        </div>
                    </div>

                    <div className='card w-full flex flex-col items-center my-4 py-4 text-center rounded-2xl lg:w-[30%]'>
                        <div>
                            <img src={TruckIcon} alt='Free delivery'></img>
                        </div>

                        <div className='mt-4 2xl:px-4'>
                            <p className='text-xl font-semibold'>Free delivery en productos seleccionados</p>
                        </div>
                    </div>

                    <div className='card w-full flex flex-col items-center py-4 text-center rounded-2xl lg:w-[30%]'>
                        <div>
                            <img src={MapIcon} alt='Cobertura nacional'></img>
                        </div>

                        <div className='mt-4 px-2'>
                            <p className='text-xl font-semibold'>Cobertura de servicio a domicilio a nivel nacional</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full bg-barlleno-red flex flex-col items-center pt-8 px-3 pb-32 lg:pb-16'>
                <div className='text-center'>
                    <p className='text-2xl font-bold text-white'>Únete a la experiencia Barlleno</p>
                </div>

                <div className='text-center py-4'>
                    <p className='text-xl font-semibold text-white'>Encuentra las mejores marcas internaciones de bebidas</p>
                </div>

                <div id='products-carousel' className='w-full my-5 lg:hidden cursor-pointer'>
                    <ProductsCarousel productsToShow={productsBarlleno} />
                </div>

                <div id='products-carousel-lg' className='w-full my-5 hidden lg:block cursor-pointer'>
                    <ProductsCarouselLargeScreen productsToShow={productsBarlleno} />
                </div>

                <div className='w-full flex justify-center mt-8'>
                    <a href='https://barlleno.app' target='_blank' className='w-full lg:w-[40%]'><button className='bg-white text-barlleno-red px-2 py-4 rounded-full w-full font-bold uppercase text-md hover:text-barlleno-blue'>Visita nuestro web commerce</button></a>
                </div>

                <div className='w-full flex justify-end'>
                    <img className='absolute w-[210px] lg:w-[250px]' src={BarllenoPhoneBlack} alt='Barlleno'></img>
                </div>

            </div>

            <div className='w-full bg-barlleno-blue flex flex-col items-center pt-40 px-4 pb-20 lg:pt-10 xl:pb-14'>
                <div className='text-center lg:w-[50%]'>
                    <p className='text-2xl font-bold text-white'>¿Necesitas tus bebidas rápidamente para una ocasión especial?</p>
                </div>

                <div className='text-center pt-4 lg:w-[60%]'>
                    <p className='text-xl font-semibold text-white'>Barlleno APP te garantiza envíos rápidos y seguros. Bebidas en tus manos en tiempo récord</p>
                </div>

                <div className='w-full flex justify-start items-start'>
                    <img className='absolute w-[210px] sm:w-[250px] lg:w-[250px] mt-8 sm:ms-8 lg:mt-0' src={BarllenoPhoneWhite} alt='Barlleno'></img>
                </div>

                <div className='w-full flex flex-row justify-end pt-6'>
                    <div className='flex flex-col items-end w-[35%] lg:w-full lg:flex-row lg:justify-center lg:items-center'>
                        <a href="https://apps.apple.com/us/app/barlleno/id1506149704?l=es&ls=1" target='_blank'>
                            <img src={DownloadAppStore} alt='Appstore' className='w-[130px]'></img>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=app.barlleno.premier ">
                            <img src={DownloadPlayStore} alt='Playstore' className='w-[130px] lg:w-[140px] my-1 lg:my-0 lg:mx-4'></img>
                        </a>

                        <a href="https://appgallery.huawei.com/#/app/C102795087">
                            <img src={DownloadAppGallery} alt='Appgallery' className='w-[130px] lg:w-[140px]'></img>
                        </a>
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-col items-center pt-20 sm:pt-24 xl:pt-12 px-3 pb-8 bg-gray-valorations'>
                <div className='text-center lg:w-[50%]'>
                    <p className='text-2xl font-bold text-barlleno-red'>Nuestros usuarios aman la APP de Barlleno</p>
                </div>

                <div className='text-center pt-4 lg:w-[50%]'>
                    <p className='text-2xl font-semibold text-barlleno-red'>¡Únete a nuestra comunidad y disfruta de la experiencia completa!</p>
                </div>

                <div className='px-10 w-full mt-4 pb-6 cursor-pointer'>
                    <ValorationsCarousel />
                </div>
            </div>

            <div className='w-full flex flex-col items-center lg:flex-row-reverse lg:justify-around pt-8'>
                <div className='w-full flex flex-col items-center lg:w-[70%]'>
                    <div className='w-full flex flex-col items-center p-2 sm:flex-row sm:justify-between'>
                        <div className='w-full flex flex-col px-2 mb-4 sm:mb-0 sm:w-[50%]'>
                            <div>
                                <p className='text-lg font-bold text-barlleno-blue'>El Salvador</p>
                            </div>

                            <div className='flex flex-row'>
                                <div className='flex flex-col justify-start mt-1 me-2'>
                                    <FaLocationPin color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>79 Av. Sur, pasaje A #222 Colonia San Benito, El Salvador</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaEnvelope color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>contacto@barlleno.app</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaPhone color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>+503 21180405</p>
                                </div>
                            </div>
                        </div>

                        <div className='w-full flex flex-col px-2 sm:w-[50%] sm:ps-10 md:ps-14'>
                            <div>
                                <p className='text-lg font-bold text-barlleno-blue'>Guatemala</p>
                            </div>

                            <div className='flex flex-row sm:flex-end'>
                                <div className='flex flex-col justify-start mt-1 me-2'>
                                    <FaLocationPin color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>13 calle 4-60 zona 10 Ciudad de Guatemala</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaEnvelope color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>contacto.gt@barlleno.app</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaPhone color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>+502 41658892</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex flex-col items-center p-2 sm:flex-row sm:justify-between'>
                        <div className='w-full flex flex-col px-2 mb-4 sm:w-[50%]'>
                            <div>
                                <p className='text-lg font-bold text-barlleno-blue'>Colombia</p>
                            </div>

                            <div className='flex flex-row'>
                                <div className='flex flex-col justify-start mt-1 me-2'>
                                    <FaLocationPin color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>Cra 13 no 90-17 Bodega l, Colombia</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaEnvelope color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>contacto.bta@barlleno.app</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaPhone color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>+57 310 2769 987</p>
                                </div>
                            </div>
                        </div>

                        <div className='w-full flex flex-col px-2 sm:ps-10 sm:w-[50%] md:ps-14'>
                            <div>
                                <p className='text-lg font-bold text-barlleno-blue'>Rep. Dominicana</p>
                            </div>

                            <div className='flex flex-row'>
                                <div className='flex flex-col justify-start mt-1 me-2'>
                                    <FaLocationPin color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>Erick Leonard Eckman No. 15, República Dominicana</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaEnvelope color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>contacto.rd@barlleno.app</p>
                                </div>
                            </div>

                            <div className='flex flex-row items-center'>
                                <div className='flex flex-col justify-start me-2'>
                                    <FaPhone color='#112A56' size='13px' />
                                </div>
                                <div>
                                    <p className='text-sm font-medium text-barlleno-blue'>+1(809) 770 3850</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full flex flex-col pt-8 lg:w-[30%]'>
                    <div className='flex flex-row justify-center'>
                        <img src={BarllenoLogoCuadrado} alt='Barlleno' className='w-[200px]'></img>
                    </div>

                    <div className='flex flex-row justify-evenly items-center py-5'>
                        <div>
                            <img src={VisaLogo} alt='Visa' className='w-[50px]'></img>
                        </div>
                        <div>
                            <img src={MastercardLogo} alt='Mastercard' className='w-[30px]'></img>
                        </div>
                        <div>
                            <img src={InstagramLogo} alt='Instagram' className='w-[25px]'></img>
                        </div>
                        <div>
                            <img src={FacebookLogo} alt='Facebook' className='w-[25px]'></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-row justify-center'>
                <hr className='w-[95%] border-solid border-gray'></hr>
            </div>

            <div className='w-full text-center py-5 px-4'>
                <p className='text-md font-medium text-barlleno-blue'>Copyright © 2023 All rights reserved | <a href='https://premte.com/' target='_blank' className='text-barlleno-red underline cursor-pointer'>Premier Tec</a></p>
            </div>
        </div>
    )
}

export default MainSectionElSalvador;