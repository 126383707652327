import { FaFacebook, FaInstagram } from "react-icons/fa6";
import AppFunctionsCarousel from './RepDominicana/AppFunctionsCarousel';
import CaracteristicasBarllenoCarousel from './RepDominicana/CaracteristicasBarllenoCarousel';
import CocktailsCarousel from './RepDominicana/CocktailsCarousel';
import InstagramPostCarousel from './RepDominicana/InstagramPostsCarousel';
import ShopNowCarousel from './RepDominicana/ShopNowCarousel';
import IconoEmail from '../assets/nimg/rd/icono_email.webp';
import Mastercard from '../assets/nimg/mastercard.svg';
import Visa from '../assets/nimg/visa.svg';
import AmericanExpress from '../assets/nimg/american.svg';
import MainCarousel from "./RepDominicana/MainCarousel";
import Promo1 from '../assets/nimg/rd/banner/promo1.png';
import Promo3 from '../assets/nimg/rd/banner/promo3.png';
import Promo6 from '../assets/nimg/rd/banner/promo6.png';
import InstagramPostCarouselLaptop from "./RepDominicana/InstagramPostCarouselLaptop";
import Caracteristica1 from '../assets/nimg/rd/CUADROS/DETODOPARATOMARBLANCO.svg';
import Caracteristica2 from '../assets/nimg/rd/CUADROS/Pedidosentiemporecordblanco.svg';
import Caracteristica3 from '../assets/nimg/rd/CUADROS/SIEMPREFRIOBLANCO.svg';
import CocktailsCarouselLaptop from "./RepDominicana/CocktailsCarouselLaptop";
import '../styles/MainSectionRepDominicana.css'

const MainSectionRepDominicana = () => {
    return (
        <div className='w-full flex flex-col items-center'>
            {/* slider */}
            <div id="main-carousel" className='w-full mb-10'>
                <MainCarousel />
            </div>

            {/* Ordena fácil y recibe rápido */}
            <div className='w-full flex justify-center items-center px-4'>
                <div className='flex flex-col items-center text-center border-2 border-rd-barlleno-red p-4 rounded-lg'>
                    <p className='text-4xl font-[900] text-rd-barlleno-red mb-4'>ORDENA FÁCIL Y RECIBE RÁPIDO</p>
                    <p className='text-5xl font-[900] text-rd-barlleno-red'>¿CÓMO?</p>
                </div>
            </div>

            {/* slider funciones de la app */}
            <div id='app-functions-carousel' className='w-full my-4 lg:hidden'>
                <AppFunctionsCarousel />
            </div>

            <div className="w-full px-4 my-10 hidden lg:flex lg:justify-evenly">
                <div className="w-[25%] descarga">

                </div>

                <div className="w-[25%] perfil">
                    
                </div>

                <div className="w-[25%] botella">
                </div>

                <div className="w-[25%] efectivo">
                    
                </div>
            </div>

            {/* contamos con la última generacion */}
            <div className='w-full flex justify-center items-center px-4 lg:my-4'>
                <div className='text-center'>
                    <p className='text-3xl font-[900] text-rd-barlleno-red mb-4'>Contamos con la última generación en seguridad de tarjetas de crédito 3Ds, para que tus datos queden protegidos.</p>
                </div>
            </div>

            {/* slider */}
            <div id='shop-now-carousel' className='w-full px-4 my-10 lg:hidden'>
                <ShopNowCarousel />
            </div>

            <div className="w-full px-4 my-4 hidden lg:flex lg:justify-evenly">
                <div className="w-[33%]">
                    <img src={Promo1} alt='Promo 1' className="w-full"></img>
                </div>

                <div className="w-[33%]">
                    <img src={Promo3} alt='Promo 3' className="w-full"></img>
                </div>

                <div className="w-[33%]">
                    <img src={Promo6} alt='Promo 6' className="w-full"></img>
                </div>
            </div>

            {/* ultimas novedades */}
            <div className='w-full flex justify-center items-center px-20 my-4'>
                <div className='flex flex-col items-center text-center border-2 border-rd-barlleno-red p-4 rounded-lg'>
                    <p className='text-3xl lg:text-5xl font-[900] text-rd-barlleno-red mb-4'>Últimas novedades</p>
                    <p className='text-3xl lg:text-5xl font-[900] text-rd-barlleno-red'>@BarllenoRD</p>
                </div>
            </div>

            {/* slider imagenes random */}
            <div id='ig-posts-carousel' className='w-full px-4 my-4 lg:hidden'>
                {/*<iframe src="https://snapwidget.com/embed/997734" className="snapwidget-widget" allowTransparency={true} style={{border: 'none', overflow: 'hidden',  width: '100%', height: '160px'}}></iframe>*/}
                <InstagramPostCarousel />
            </div>

            <div id='ig-posts-carousel-lg' className='w-full px-4 mt-8 hidden lg:block'>
                {/*<iframe src="https://snapwidget.com/embed/997734" className="snapwidget-widget" allowTransparency={true} style={{border: 'none', overflow: 'hidden',  width: '100%', height: '160px'}}></iframe>*/}
                <InstagramPostCarouselLaptop />
            </div>

            {/* slider fondo barlleno */}
            <div id='caracteristicas-barlleno-carousel' className='w-full px-4 my-4 lg:hidden'>
                <CaracteristicasBarllenoCarousel />
            </div>

            <div className='w-full px-4 my-10 hidden lg:flex lg:justify-between lg:items-center'>
                <div className='w-[32%] flex flex-col items-center'>
                    <img src={Caracteristica1} alt='Promo 1' className="w-full"></img>
                </div>

                <div className='w-[32%] flex flex-col items-center'>
                    <img src={Caracteristica2} alt='Promo 3' className="w-full"></img>
                </div>

                <div className='w-[32%] flex flex-col items-center'>
                    <img src={Caracteristica3} alt='Promo 6' className="w-full"></img>
                </div>
            </div>

            {/* titulo barlleno cocktails*/}
            <div className='w-full flex justify-center items-center px-4'>
                <div className='flex flex-col items-center text-center border-2 border-rd-barlleno-red px-8 lg:px-40 py-1 rounded-lg'>
                    <p className='text-3xl font-[900] text-rd-barlleno-red mb-4'>Barlleno cocktails</p>
                </div>
            </div>

            {/* slider barlleno cocktails */}
            <div id='cocktails-carousel' className='w-full px-4 my-4 lg:hidden'>
                <CocktailsCarousel />
            </div>

            <div id='cocktails-carousel-lg' className='w-full px-4 my-10 hidden lg:block'>
                <CocktailsCarouselLaptop />
            </div>

            {/* suscribete a nuestra seccion */}
            <div className='w-full flex flex-col items-center'>
                <div className='w-full flex flex-col items-center'>
                    <div>
                        <img src={IconoEmail} alt='email'></img>
                    </div>

                    <div className='text-center'>
                        <p className='text-2xl font-[900] text-rd-barlleno-red mb-4'>Suscribete a nuestro mailing y manténte informado con las últimas novedades de ofertas, nuevos productos y todo lo que te ofrecemos exclusivo en tu APP de bebidas BARLLENO</p>
                    </div>
                </div>
            </div>

            {/* footer */}
            <div className='w-full flex flex-col pt-10 pb-6 px-4'>
                <div className="w-full flex flex-col items-center md:flex-row md:items-start">
                    <div className='w-full flex flex-col px-4 md:w-[50%]'>
                        <div className='w-full'>
                            <p className='text-2xl font-[800] text-barlleno-red mb-4'>CONTÁCTANOS</p>
                        </div>

                        <div className='w-full flex flex-col'>
                            <div>
                                <p className='text-lg font-[800] text-barlleno-red mb-4'>República Dominicana</p>
                            </div>

                            <div>
                                <p className='text-lg font-medium'>+1(809) 770 3850</p>
                            </div>

                            <div>
                                <p className='text-lg font-medium'>Erick Leonard Eckman No. 15, República Dominicana</p>
                            </div>

                            <div className='w-full flex flex-nowrap items-center'>
                                <FaFacebook size='20px' />
                                <FaInstagram size='20px' />
                                <p className='text-lg font-medium ms-1'>contacto.rd@barlleno.app</p>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex flex-col items-center mt-4 md:mt-0 md:w-[50%] md:items-end'>
                        <div>
                            <p className='text-lg font-[800] text-barlleno-red mb-2 md:text-xl text-center'>¡Síguenos en nuestras redes sociales!</p>
                        </div>
                    
                        <div className="flex flex-row mb-2 md:justify-center md:hidden">
                            <FaFacebook size='20px' />
                            <FaInstagram style={{ marginLeft: '2px' }} size='20px' />
                        </div>

                        <div className="flex flex-col sm:flex-row px-4">
                            <img src={Mastercard} alt="Mastercard" className="w-[100px] sm:w-[120px]"></img>
                            <img src={Visa} alt="Visa" className="w-[100px] mx-2 sm:w-[120px] md:mx-0"></img>
                            <img src={AmericanExpress} alt="American" className="w-[100px] sm:w-[120px]"></img>
                        </div>
                    </div>
                </div>

                <div className='w-full flex flex-row justify-center mt-4'>
                    <hr className='w-[98%] border-solid border-gray'></hr>
                </div>

                <div className='w-full text-center pt-4 px-4'>
                    <p className='text-md font-medium text-barlleno-blue'>Copyright © 2023 All rights reserved | <a href='https://premte.com/' target='_blank' className='text-barlleno-red underline cursor-pointer'>Premier Tec</a></p>
                </div>
            </div>

        </div>
    );
}

export default MainSectionRepDominicana;