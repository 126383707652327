// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import "../../styles/RepDominicana/MainCarousel.css";

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation
} from 'swiper/core';

import FondoRd3 from '../../assets/nimg/rd/fondord3.webp';
import IconoBarlleno from '../../assets/nimg/rd/iconobarlleno.webp';
import Playstore from '../../assets/nimg/rd/rdgooglebtn.webp';
import Appstore from '../../assets/nimg/rd/rdapplebtn.webp';
import Appgallery from '../../assets/nimg/rd/rdhuaywaeibtn.webp';
import Celular from '../../assets/nimg/rd/celularrd.webp';
import Banner3 from '../../assets/nimg/b3.webp';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

const MainCarousel = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    "delay": 3500,
                    "disableOnInteraction": false
                }}
                navigation={true}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={FondoRd3} alt='Fondo' className="w-full relative">
                        </img>

                        <div className="w-full absolute flex flex-row">
                            <div className="w-[40%] flex flex-col items-center justify-center lg:ms-12">
                                <div className="w-full flex justify-center">
                                    <img src={IconoBarlleno} alt="Barlleno" className="w-[50px]"></img>
                                </div>

                                <div className="w-full flex justify-center text-center">
                                    <p className='text-md lg:text-lg font-[900] 2xl:text-2xl text-rd-barlleno-red'>Tus bebidas a domicilio</p>
                                </div>

                                <div className="w-full flex justify-center text-center">
                                    <p className='text-lg lg:text-xl font-[900] 2xl:text-3xl text-rd-barlleno-red'>a sólo un click de distancia</p>
                                </div>

                                <div className="w-full hidden sm:flex justify-center text-center mt-2">
                                    <p className='text-xl lg:text-2xl font-[900] 2xl:text-4xl text-rd-barlleno-red'>Descarga el App</p>
                                </div>

                                <div className="w-full justify-center items-center pt-2 hidden sm:flex">                             
                                    <a href="https://play.google.com/store/apps/details?id=app.barlleno.premier ">
                                        <img src={Playstore} alt="playstore" className="w-[80px] lg:w-[100px] xl:w-[120px]"></img>
                                    </a>

                                    <a href="https://apps.apple.com/us/app/barlleno/id1506149704?l=es&ls=1" target='_blank'>
                                        <img src={Appstore} alt="appstore" className="w-[80px] lg:w-[100px] xl:w-[120px]"></img>
                                    </a> 

                                    <a href="https://appgallery.huawei.com/#/app/C102795087">
                                        <img src={Appgallery} alt="appgallery" className="w-[80px] lg:w-[100px] xl:w-[120px]"></img>
                                    </a>           
                                </div>
                            </div>
                            
                            <div className="w-[60%]">
                                <img src={Celular} alt="Celular" className="w-[55%] ms-8"></img>
                            </div>
                        </div>                  
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-full flex flex-col items-center'>
                        <img src={Banner3} alt='Fondo' className="w-full"></img>               
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default MainCarousel;