import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { LocationContext } from '../context';
import Button from '@mui/material/Button'
import { countriesCode } from '../helpers/countriesCode';
import BarllenoLogo from '../assets/img/barlleno-logo-horizontal.png';
import HondurasIcon from '../assets/icons/honduras-icon.png';
import ColombiaIcon from '../assets/icons/colombia-icon.png';
import RepDominicanaIcon from '../assets/icons/republica-dominicana-icon.png';
import GuatemalaIcon from '../assets/icons/guatemala-icon.png';
import ElSalvadorIcon from '../assets/icons/el-salvador-icon.png';

const MenuPage = () => {
    const { setCountry } = useContext(LocationContext);
    const navigate = useNavigate();

    const ElSalvadorSelectedHandler = ( countryCode: string ) => {
        setCountry(countryCode);
        sessionStorage.setItem('country', countryCode);
        navigate("/home");
    }

    const RepDominicanaSelectedHandler = () => {
        setCountry(countriesCode.RepDominicana);
        sessionStorage.setItem('country', countriesCode.RepDominicana);
        navigate("/home");
    }

    useEffect(() => {
        let country = sessionStorage.getItem('country');

        if(country !== null && country !== undefined){
            setCountry(country);
            navigate("/home");
        }
    }, [])

    return (
        <div className='w-full flex justify-center'>
            <div className='flex flex-col justify-center items-center min-h-screen container px-4'>
                <div className='w-full flex justify-center text-center'>
                    <p className='text-3xl font-bold text-barlleno-red'>¡Bienvenid@s!</p>
                </div>

                <div className='w-full flex justify-center py-4'>
                    <img src={BarllenoLogo} alt='Barlleno'></img>
                </div>

                <div className='w-full flex justify-center text-center'>
                    <p className='text-2xl font-bold text-barlleno-blue'>¿A dónde serviremos tus bebidas?</p>
                </div>

                <div className='flex flex-col justify-center py-4 w-full min-[350px]:w-[80%] min-[450px]:w-[70%] sm:w-[60%] md:w-[50%] lg:w-[30%] xl:w-[25%]'>
                    <div className='border border-gray flex items-center cursor-pointer hover:bg-gray px-2 sm:px-4 rounded-t-md' onClick={RepDominicanaSelectedHandler}>
                        <div className='w-[20%] xl:w-[15%] me-2'>
                            <img src={RepDominicanaIcon} alt='Rep. Dominicana' className=''></img>
                        </div>

                        <div className='w-[80%]'>
                            <p className='text-sm min-[350px]:text-md sm:text-lg font-bold text-barlleno-blue'>República Dominicana</p>
                        </div>
                    </div>

                    <div className='border border-gray flex items-center cursor-pointer hover:bg-gray px-2 sm:px-4' onClick={() => ElSalvadorSelectedHandler(countriesCode.ElSalvador)}>
                        <div className='w-[20%] xl:w-[15%] me-2'>
                            <img src={ElSalvadorIcon} alt='El Salvador' className=''></img>
                        </div>

                        <div className='w-[80%]'>
                            <p className='text-sm min-[350px]:text-md sm:text-lg font-bold text-barlleno-blue'>El Salvador</p>
                        </div>
                    </div>

                    <div className='border border-gray flex items-center cursor-pointer hover:bg-gray px-2 sm:px-4' onClick={() => ElSalvadorSelectedHandler(countriesCode.Colombia)}>
                        <div className='w-[20%] xl:w-[15%] me-2'>
                            <img src={ColombiaIcon} alt='Colombia' className=''></img>
                        </div>

                        <div className='w-[80%]'>
                            <p className='text-sm min-[350px]:text-md sm:text-lg font-bold text-barlleno-blue'>Colombia</p>
                        </div>
                    </div>

                    <div className='border border-gray flex items-center cursor-pointer hover:bg-gray px-2 sm:px-4' onClick={() => ElSalvadorSelectedHandler(countriesCode.Guatemala)}>
                        <div className='w-[20%] xl:w-[15%] me-2'>
                            <img src={GuatemalaIcon} alt='Guatemala' className=''></img>
                        </div>

                        <div className='w-[80%]'>
                            <p className='text-sm min-[350px]:text-md sm:text-lg font-bold text-barlleno-blue'>Guatemala</p>
                        </div>
                    </div>

                    <div className='border border-gray flex items-center cursor-pointer hover:bg-gray px-2 sm:px-4 rounded-b-md' onClick={() => ElSalvadorSelectedHandler(countriesCode.Honduras)}>
                        <div className='w-[20%] xl:w-[15%] me-2'>
                            <img src={HondurasIcon} alt='Honduras' className=''></img>
                        </div>

                        <div className='w-[80%]'>
                            <p className='text-sm min-[350px]:text-md sm:text-lg font-bold text-barlleno-blue'>Honduras</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuPage;