import Header from "../components/Header";
import { useContext } from 'react';
import { LocationContext } from '../context';
import MainSectionElSalvador from "../components/MainSectionElSalvador";

const ElSalvadorPage = () => {
    const { countryCode } = useContext(LocationContext);

    return (
        <div className="flex flex-col items-center justify-center w-full">
            <Header countryCode={countryCode}/>
            <div className="w-full pt-20 lg:pt-24 flex flex-col items-center 2xl:container">
                <MainSectionElSalvador />
            </div>           
        </div>
    )
}

export default ElSalvadorPage;