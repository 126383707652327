import { BarllenoProduct } from "../types/ElSalvadorTypes";

// Images
import AlbarinoBlanco from '../assets/img/albarino-blanco.png';
import CuttySark from '../assets/img/cutty-sark.png';
import EvanWilliams from '../assets/img/evan-williams.png';
import HpNotiq from '../assets/img/hpnotiq.png';
import Mahou from '../assets/img/mahou.png';
import OldParr from '../assets/img/old-parr.png';
import SanPellegrino from '../assets/img/san-pellegrino.png';
import EspolonCristalino from '../assets/img/espolon-cristalino.png';

export const productsBarlleno: BarllenoProduct[] = [
    {
        imgUrl: AlbarinoBlanco,
        name: 'Albarino Blanco'
    },
    {
        imgUrl: CuttySark,
        name: 'Cutty Sark'
    },
    {
        imgUrl: EvanWilliams,
        name: 'Evan Williams'
    },
    {
        imgUrl: HpNotiq,
        name: 'HpNotiq'
    },
    {
        imgUrl: Mahou,
        name: 'Mahou'
    },
    {
        imgUrl: OldParr,
        name: 'Old Parr'
    },
    {
        imgUrl: SanPellegrino,
        name: 'San Pellegrino'
    },
    {
        imgUrl: EspolonCristalino,
        name: 'Espolón Cristalino'
    },
]