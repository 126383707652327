// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FaStar } from "react-icons/fa6";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import '../../styles/ElSalvador/ProductsCarousel.css';

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation
} from 'swiper/core';

import BarllenoImagotipo from '../../assets/img/barlleno-imagotipo.png';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

const ValorationsCarousel = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    "delay": 5000,
                    "disableOnInteraction": false
                }}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='bg-transparent px-4'>
                        <div className='bg-gray-FA flex flex-col items-center rounded-xl pt-4'>
                            <div className='bg-barlleno-red p-4 rounded-lg'>
                                <img src={BarllenoImagotipo} alt='Albarino blanco'></img>
                            </div>

                            <div className='w-full flex flex-row justify-center py-4'>
                                <FaStar className='me-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='ms-1' color='#FF9933' />
                            </div>

                            <div className='text-center'>
                                <p className='text-2xl font-bold text-barlleno-blue'>Alicia Mejía</p>
                            </div>

                            <div className='w-full text-center pt-3'>
                                <p className='text-md sm:text-lg lg:text-xl font-medium text-barlleno-blue'>Esta increíble aplicación de entrega de bebidas garantiza que mis bebidas favoritas estén a sólo unos toques de distancia, entregadas directamente en mi puerta con la máxima comodidad.</p>
                            </div>
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='bg-transparent px-4'>
                        <div className='bg-gray-valorations flex flex-col items-center rounded-xl pt-4'>
                            <div className='bg-barlleno-red p-4 rounded-lg'>
                                <img src={BarllenoImagotipo} alt='Albarino blanco'></img>
                            </div>

                            <div className='w-full flex flex-row justify-center py-4'>
                                <FaStar className='me-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='ms-1' color='#FF9933' />
                            </div>

                            <div className='text-center'>
                                <p className='text-2xl font-bold text-barlleno-blue'>Marcelo Bran</p>
                            </div>

                            <div className='w-full text-center pt-3'>
                                <p className='text-md sm:text-lg lg:text-xl font-medium text-barlleno-blue'>Barlleno es mi aplicación de reparto de bebidas favorita. Con una amplia selección de bebidas y una entrega rapidísima, se ha convertido en una parte esencial de mi vida.</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='bg-transparent px-4'>
                        <div className='bg-gray-valorations flex flex-col items-center rounded-xl pt-4'>
                            <div className='bg-barlleno-red p-4 rounded-lg'>
                                <img src={BarllenoImagotipo} alt='Albarino blanco'></img>
                            </div>

                            <div className='w-full flex flex-row justify-center py-4'>
                                <FaStar className='me-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='ms-1' color='#FF9933' />
                            </div>

                            <div className='text-center'>
                                <p className='text-2xl font-bold text-barlleno-blue'>Henry Luna</p>
                            </div>

                            <div className='w-full text-center pt-3'>
                                <p className='text-md sm:text-lg lg:text-xl font-medium text-barlleno-blue'>Gracias a Barlleno, ahora puedo satisfacer mis antojos con sólo unos toques en mi teléfono. Es el mejor compañero de bebidas que nunca decepciona.</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='bg-transparent px-4'>
                        <div className='bg-gray-valorations flex flex-col items-center rounded-xl pt-4'>
                            <div className='bg-barlleno-red p-4 rounded-lg'>
                                <img src={BarllenoImagotipo} alt='Albarino blanco'></img>
                            </div>

                            <div className='w-full flex flex-row justify-center py-4'>
                                <FaStar className='me-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='ms-1' color='#FF9933' />
                            </div>

                            <div className='text-center'>
                                <p className='text-2xl font-bold text-barlleno-blue'>Fernando Melgar</p>
                            </div>

                            <div className='w-full text-center pt-3'>
                                <p className='text-md sm:text-lg lg:text-xl font-medium text-barlleno-blue'>Con una amplia selección de bebidas y un servicio de entrega rapidísimo, Barlleno se ha convertido en mi aplicación favorita para todas mis necesidades de bebidas. ¡Salud a Barlleno por hacer que cada sorbo sea realmente delicioso!</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='bg-transparent px-4'>
                        <div className='bg-gray-valorations flex flex-col items-center rounded-xl pt-4'>
                            <div className='bg-barlleno-red p-4 rounded-lg'>
                                <img src={BarllenoImagotipo} alt='Albarino blanco'></img>
                            </div>

                            <div className='w-full flex flex-row justify-center py-4'>
                                <FaStar className='me-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='mx-1' color='#FF9933' />
                                <FaStar className='ms-1' color='#FF9933' />
                            </div>

                            <div className='text-center'>
                                <p className='text-2xl font-bold text-barlleno-blue'>Álvaro Guandique</p>
                            </div>

                            <div className='w-full text-center pt-3'>
                                <p className='text-md sm:text-lg lg:text-xl font-medium text-barlleno-blue'>Hace poco empecé a usar Barlleno y ¡me alegro mucho de haberlo hecho! Esta aplicación de entrega de bebidas ha hecho que sea muy fácil recibir mis bebidas favoritas directamente en mi puerta. El proceso de pedido es sencillo y la entrega siempre es rápida</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default ValorationsCarousel;