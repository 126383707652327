// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import '../../styles/ElSalvador/ProductsCarousel.css';

// import Swiper core and required modules
import SwiperCore, {
    Autoplay, Navigation
} from 'swiper/core';

import { BarllenoProduct } from "../../types/ElSalvadorTypes";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

const ProductsCarousel = ( {productsToShow} : {productsToShow: BarllenoProduct[]} ) => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    "delay": 3500,
                    "disableOnInteraction": false
                }}
                navigation={true}
                className="mySwiper"
            >
                {
                    productsToShow.map( (product: BarllenoProduct, index: number) => (
                        <SwiperSlide key={index}>
                            <div className='bg-transparent px-14 sm:px-20'>
                                <div className="bg-white flex flex-col items-center rounded-xl">
                                    <div className="pt-4">
                                        <img src={product.imgUrl} alt={product.name} className='w-[300px]'></img>
                                    </div>

                                    <div className='text-center py-5'>
                                        <p className='text-xl font-bold text-barlleno-blue'>{product.name}</p>
                                    </div>
                                </div>                              
                            </div>
                        </SwiperSlide>
                    ))
                }               
            </Swiper>
        </>
    )
}

export default ProductsCarousel;